import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css'
import Header from './globals/Header'
import Footer from './globals/Footer'
import { brandColor } from './style/brandConfig'
import Home from './screens/Home'
import MyLibrary from './screens/MyLibrary'
import CustomPage from './screens/CustomPage'
import Intermediate from './screens/Intermediate'
import ExplorePage from './screens/Explore'
import Registration from './screens/Registration'
import Account from './screens/Account'
import SignIn from './screens/SignIn'
import Products from './screens/Products'
import { useGlobalQuery } from './graphql'
import { useDispatch, useSelector } from 'react-redux'
import VideoPlayer from './common/VideoPlayer'
import { WindowSize } from './constants/enums'
import ExploreAll from './common/ExploreAll'
import { selectExploreAll, setExploreAll } from './store/exploreAll/exploreAllSlice'
import { setBrandData, setNativeAppMessage, setWindowSize } from './store/base/commonSlice'
import { selectSession } from './store/auth/sessionSlice'
import ResetPassword from './screens/ResetPassword'
import VerifyPurchase from './screens/Purchase/VerifyPurchase'
import PollMe from './common/PollMe'
import PageNotFound from './screens/PageNotFound'
import IapListener from './common/IapListener'

function App() {
  const [scrolling, setScrolling] = useState(false)
  const scrollingRef = React.useRef(scrolling)

  const dispatch = useDispatch()
  const { signedIn } = useSelector(selectSession)

  const { open, id, catalogId, search } = useSelector(selectExploreAll)

  const { data } = useGlobalQuery({
    fetchPolicy: 'cache-and-network',
  })

  const messageListener = (nativeEvent: MessageEvent) => {
    try {
      if (JSON.parse(nativeEvent.data)?.isNative) {
        dispatch(setNativeAppMessage(JSON.parse(nativeEvent.data)))
      }
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        throw e
      }
    }
  }

  let headerData = {}
  let footerData = null

  if (data?.global) {
    headerData = data?.global?.header
    footerData = data?.global?.footer
    dispatch(setBrandData(data?.global?.header?.brand))
  }

  const platformBrand = 'Ignatius+'

  const handleScroll = () => {
    if (window.scrollY > 0 && !scrollingRef.current) {
      setScrolling(true)
    } else if (window.scrollY === 0 && scrollingRef.current) {
      setScrolling(false)
    }
  }

  useEffect(() => {
    scrollingRef.current = scrolling
  }, [scrolling])

  const handleResize = () => {
    if (signedIn) {
      if (window.innerWidth > 1320) {
        dispatch(setWindowSize(WindowSize.DesktopFull))
      } else if (window.innerWidth <= 1320 && window.innerWidth > 1151) {
        dispatch(setWindowSize(WindowSize.DesktopLarge))
      } else if (window.innerWidth <= 1151 && window.innerWidth > 985) {
        dispatch(setWindowSize(WindowSize.DesktopMedium))
      } else if (window.innerWidth <= 985) {
        dispatch(setWindowSize(WindowSize.Mobile))
      }
    } else {
      if (window.innerWidth > 1385) {
        dispatch(setWindowSize(WindowSize.DesktopFull))
      } else if (window.innerWidth <= 1385 && window.innerWidth > 1235) {
        dispatch(setWindowSize(WindowSize.DesktopLarge))
      } else if (window.innerWidth <= 1235 && window.innerWidth > 1075) {
        dispatch(setWindowSize(WindowSize.DesktopMedium))
      } else if (window.innerWidth <= 1075) {
        dispatch(setWindowSize(WindowSize.Mobile))
      }
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    window.addEventListener('message', messageListener)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener('message', messageListener)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('message', messageListener)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener('message', messageListener)
    }
  }, [])

  return (
    <div className="App">
      {signedIn && <PollMe />}
      <IapListener />
      <VideoPlayer>
        <Header scrolling={scrolling} data={headerData} />
        <div style={styles.backgroundImage}>
          <div style={styles.bottomLayer} className={'bottomLayer'}>
            <div style={styles.topLayerMobile} />
          </div>
        </div>
        <div style={styles.contentBody}>
          <Routes>
            <Route path={'/'} element={<Home />} />
            <Route path={'/my-library'} element={<MyLibrary />} />
            <Route path={'/:pageSlug'} element={<CustomPage />} />
            <Route path={'/content/:contentId'} element={<Intermediate scrolling={scrolling} />} />
            <Route path={'/explore'} element={<ExplorePage scrolling={scrolling} brandedName={platformBrand} />} />
            <Route path={'/account'} element={<Account />} />
            <Route path={'/sign-in'} element={<SignIn />} />
            <Route path={'/reset-password'} element={<ResetPassword />} />
            <Route path={'/registration'} element={<Registration />} />
            <Route path={'/purchase/verify'} element={<VerifyPurchase />} />
            <Route path={'/purchase/:contentId'} element={<Products />} />
            <Route path={'/subscribe/content/:contentId'} element={<Products />} />
            <Route path={'/subscribe/:productId'} element={<Products />} />
            {['/page-not-found', '*'].map((path) => (
              <Route key={path} path={path} element={<PageNotFound />} />
            ))}
          </Routes>
          <ExploreAll
            open={open}
            closeExploreAll={() => dispatch(setExploreAll({ open: false, id: null, catalogId: null }))}
            id={id}
            catalogId={catalogId}
            search={search}
          />
          <Footer data={footerData} />
        </div>
      </VideoPlayer>
    </div>
  )
}

const styles = {
  topLayerDesktop: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100%',
    backgroundImage: 'radial-gradient(ellipse at center, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.95)',
  } as React.CSSProperties,
  topLayerMobile: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100%',
    backgroundImage: 'radial-gradient(circle at center, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.95)',
  } as React.CSSProperties,
  contentBody: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    overflow: 'hidden',
  } as React.CSSProperties,
  bottomLayer: {
    height: '100vh',
    width: '100%',
    backgroundColor: brandColor,
    color: '#404040',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
  } as React.CSSProperties,
  backgroundImage: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    backgroundBlendMode: 'multiply',
  } as React.CSSProperties,
}

export default App
