import React from 'react'
import LiveNowDropdown from './LiveNowDropdown'
import ProfileDropdown from './ProfileDropdown'
import RegisterButton from '../../../common/buttons/RegisterButton'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { WindowSize } from '../../../constants/enums'
import { selectWindowSize } from '../../../store/base/commonSlice'
import { selectSession } from '../../../store/auth/sessionSlice'

const ProfileSection = () => {
  const redirectUrl = location.pathname !== '/' ? location.pathname : ''
  const { signedIn } = useSelector(selectSession)

  const navigate = useNavigate()
  const windowSize = useSelector(selectWindowSize)

  const containerStyle =
    windowSize === WindowSize.Mobile ? { ...styles.container, ...styles.containerMobile } : { ...styles.container }

  return (
    <div style={containerStyle}>
      <LiveNowDropdown />
      {signedIn || windowSize === WindowSize.Mobile ? (
        <ProfileDropdown signedIn={signedIn} windowSize={windowSize} />
      ) : (
        <>
          <div style={{ ...styles.sectionWrapper }} onClick={() => navigate('/sign-in', { state: { redirectUrl } })}>
            <div style={styles.signInText}>SIGN IN</div>
          </div>
          <div style={styles.sectionWrapper}>
            <RegisterButton redirectUrl={redirectUrl} />
          </div>
        </>
      )}
    </div>
  )
}

const styles: CSSObject = {
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'right',
    fontWeight: 400,
    gap: 21,
    alignItems: 'center',
  } as React.CSSProperties,
  containerMobile: {
    gap: 15,
  },
  sectionWrapper: {
    //marginTop: -7.5,
    display: 'flex',
    flexDirection: 'column',
  } as React.CSSProperties,
  item: {
    marginRight: 0,
  } as React.CSSProperties,
  profileButtonMobile: {
    padding: 10,
    height: 17,
    cursor: 'pointer',
  } as React.CSSProperties,
  profileDropdown: {} as React.CSSProperties,
  profileImage: {
    marginTop: 2.5,
    width: 35,
  } as React.CSSProperties,
  profileDropdownMobile: {
    position: 'fixed',
    top: 60,
    left: 0,
    right: 0,
    bottom: 0,
  },
  signInText: {
    //marginTop: 14,
    //marginRight: 24,
    padding: 10,
    fontWeight: 600,
    letterSpacing: 1.8,
    fontSize: 14,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  icons: {
    height: 17,
  } as React.CSSProperties,
}

export default ProfileSection
