import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MediaRow from '../../common/MediaRow'
import { brandColor } from '../../style/brandConfig'
import ProgressBar from '../../common/ProgressBar'
import './index.css'
import { MediaRowFieldsFragment, useMediaItemQuery } from '../../graphql'
import { formatScheduledTimeRange, formatTimeString } from '../../utils/time'
import LoadingScreen from '../../common/LoadingScreen'
import ActionButtons from '../../common/ActionButtons'
import getPaywallText from '../../utils/paywallText'
import useLiveNow from '../../utils/hooks/useLiveNow'
import CheckmarkIcon from '../../assets/CheckmarkIcon.svg'
import { ReactComponent as TVIcon } from '../../assets/TVIcon.svg'
import { ReactComponent as StackIcon } from '../../assets/StackIcon.svg'

const Intermediate = (props: Intermediate) => {
  const { scrolling } = props
  const navigate = useNavigate()

  const params = useParams()
  if (!params.contentId) return null

  const { data, loading } = useMediaItemQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        id: params.contentId,
      },
    },
  })

  if (!loading && !data) {
    navigate('/page-not-found')
  }

  const mediaItem = data?.mediaItem
  const liveNow = useLiveNow(mediaItem?.id || null)
  let item = mediaItem

  // todo: this needs to be deprecated

  let row: MediaRowFieldsFragment | undefined

  //todo: implement the media row for intermediate screens
  /*if (mediaItem?.categories?.length) {
    const category = mediaItem?.categories[0]
    row = {
      title: category?.name,
      items: {
        hasMore: false,
        total: 0,
        list: category?.content || [],
      },
      type: MediaRowType.Standard,
    }
  }*/

  useEffect(() => {
    if (item) {
      let backdrop = document?.querySelector('.bottomLayer') as HTMLElement
      if (item?.wideBackdrop?.src && backdrop) {
        backdrop.style.backgroundColor = 'rgb(80, 80, 80)'
      }
      return () => {
        if (backdrop) {
          backdrop.style.backgroundColor = brandColor
        }
      }
    }
  }, [item])

  const type = item?.__typename?.toLowerCase()
  let progress = 0

  if (item?.viewer?.progress) {
    progress = item.viewer.progress
  }

  const now = new Date()

  const startTime =
    item && 'scheduledStart' in item ? new Date(item?.scheduledStart ? item?.scheduledStart : null) : new Date()
  const endTime = item && 'scheduledEnd' in item ? new Date(item?.scheduledEnd ? item?.scheduledEnd : null) : new Date()
  const createdTime = new Date(item?.createdAt)
  const publishOn = new Date(item?.publishOn ? item?.publishOn : null)

  let duration = 0

  if (item?.duration) {
    duration = item?.duration
  } else {
    duration = Math.abs(+endTime - +startTime) / 1000
  }

  let upcomingText = ''
  let header = type === 'event' ? 'Past Event' : 'Video'
  let timeLeft = 0

  let eventStatus: 'live' | 'upcoming' | 'past' | undefined

  if (publishOn > now && type === 'video') {
    header = 'Upcoming Video'
    upcomingText = `Available ${publishOn.toLocaleString('default', {
      month: 'long',
    })} ${publishOn.getDate()}, ${publishOn.getFullYear()} at ${publishOn.toLocaleTimeString('en-US', {
      hour: 'numeric',
      hour12: true,
      minute: 'numeric',
    })}`
  }

  if (eventStatus === 'past' || type === 'video') {
    timeLeft = (duration - progress) / 60
  }

  if (eventStatus === 'live') {
    const end = item && 'scheduledEnd' in item ? new Date(item?.scheduledEnd ? item?.scheduledEnd : null) : new Date()
    timeLeft = Math.abs(+end - +now) / 1000 / 60
  }

  timeLeft = +timeLeft.toFixed(0)

  let timeLeftString = formatTimeString({ minutes: timeLeft })

  if (liveNow) {
    if (item && (!('scheduledEnd' in item) || ('scheduledEnd' in item && !item?.scheduledEnd))) {
      progress = 1
      timeLeftString = ''
    } else {
      progress = liveNow.progress
      timeLeftString = liveNow.minutesRemainingText
      //const timeElapsedText = liveNow.minutesElapsedText
    }
    header = 'Ongoing Event'
    eventStatus = 'live'
  } else if (type === 'event') {
    const start = item && 'scheduledStart' in item ? new Date(item.scheduledStart) : new Date()
    const end = item && 'scheduledEnd' in item ? new Date(item.scheduledEnd) : new Date()
    if (now < start) {
      header = 'Upcoming Event'
      eventStatus = 'upcoming'
      upcomingText = 'Come back later to watch live'
    } else if (now > end) {
      eventStatus = 'past'
    } else {
      eventStatus = 'live'
      //itemStatus = 'live'
      header = 'Ongoing Event'
    }
  }

  let durationText = ''

  if (type === 'video') {
    durationText += ` · ${createdTime.getFullYear()}`
    const durationString = formatTimeString({ seconds: duration })
    if (durationString) durationText += ` · ${durationString}`
  } else {
    durationText += ` · ${formatScheduledTimeRange(startTime, endTime)}`
  }

  const { paywallInfo } = getPaywallText(mediaItem)

  const intermediateHeaderLeftStyles = mediaItem?.purchased
    ? ({
        ...styles.intermediateHeaderInnerContainer,
        paddingRight: 22,
        borderRight: '1px solid #404040',
      } as React.CSSProperties)
    : styles.intermediateHeaderInnerContainer

  return (
    <LoadingScreen loading={loading && !data}>
      <div className={'intermediateWrapper'} style={styles.container}>
        {item?.wideBackdrop?.src ? (
          <div
            className={'intermediateBackgroundImg'}
            style={{ ...styles.intermediateBackgroundImg, backgroundImage: `url(${item?.wideBackdrop?.src})` }}>
            <div className={'intermediateBackgroundOverlayTop'} />
            {/*<div className={'intermediateBackgroundOverlayLeft'} />*/}
            <div
              className={'intermediateBackgroundOverlayCover'}
              style={{
                ...styles.intermediateBackgroundOverlayCover,
                opacity: scrolling ? 1 : 0.5,
              }}
            />
            <div className={'intermediateBackgroundOverlayBottom'} />
          </div>
        ) : null}
        <div
          className={'intermediateBackgroundOverlay'}
          style={{
            ...styles.intermediateBackgroundOverlay,
            background: item?.wideBackdrop?.src
              ? 'transparent linear-gradient(90deg, #0D0D0D 0%, #0D0D0D00 100%) 0% 0% no-repeat padding-box'
              : 'none',
          }}>
          <div className={'intermediateContainer'} style={styles.intermediateContainer}>
            <div className={'intermediateLeft'} style={styles.intermediateLeft}>
              <div className={'intermediateContent'} style={styles.intermediateContent}>
                {item?.logo?.src ? (
                  <div className={'intermediateLogoContainer'} style={styles.intermediateLogoContainer}>
                    <img
                      src={item?.logo?.src}
                      alt={item?.logo?.src}
                      className={'intermediateLogo'}
                      style={styles.intermediateLogo}
                    />
                  </div>
                ) : (
                  <span className={'intermediateTitle'} style={styles.intermediateTitle}>
                    {item?.name}
                  </span>
                )}
                {item?.subtitle && (
                  <span className={'intermediateSubtitle'} style={styles.intermediateSubtitle}>
                    {item?.subtitle}
                  </span>
                )}
                <div className={'intermediateHeaderDivider'} style={styles.intermediateHeaderDivider} />
                <div className={'intermediateHeaderContainer'} style={styles.intermediateHeaderContainer}>
                  <div style={intermediateHeaderLeftStyles}>
                    <span className={'intermediateHeader'} style={styles.intermediateHeader}>
                      {header + durationText}
                    </span>
                  </div>
                  {mediaItem?.purchased ? (
                    <div style={{ ...styles.intermediateHeaderInnerContainer, ...styles.intermediateHeaderRight }}>
                      <img src={CheckmarkIcon} style={styles.purchasedIcon} alt={''} />
                      <span className={'intermediateDurationText'} style={styles.intermediateDurationText}>
                        Purchased
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className={'intermediateInfoContainer'} style={styles.intermediateInfoContainer}>
                  <div className={'intermediateBtnContainer'} style={styles.intermediateBtnContainer}>
                    <div className={'intermediateBtnWrapper'} style={styles.intermediateBtnWrapper}>
                      <ActionButtons mediaItem={mediaItem} progress={progress} />
                    </div>
                    {paywallInfo.length && mediaItem?.restrictions?.length ? (
                      <div className={'paywallInfoWrapper'} style={styles.paywallInfoWrapper}>
                        {paywallInfo.map((info, index) => {
                          const IconComponent = info.type === 'ppv' ? TVIcon : info.type === 'bundle' ? StackIcon : null
                          return (
                            <div
                              className={'paywallInfoSingleWrapper'}
                              key={index}
                              style={styles.paywallInfoSingleWrapper}>
                              {IconComponent && <IconComponent style={styles.paywallInfoIcon} />}
                              <div style={styles.paywallInfoText} key={index}>
                                {info.text}
                              </div>
                              {paywallInfo.length > 1 && index !== paywallInfo.length - 1 && (
                                <div className={'paywallInfoDivider'} style={styles.paywallInfoDivider} />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <div className={'intermediateInfo'} style={styles.intermediateInfo}>
                        {eventStatus === 'upcoming' ? (
                          <span className={'intermediateUpcomingText'} style={styles.intermediateUpcomingText}>
                            {upcomingText}
                          </span>
                        ) : progress ? (
                          <div
                            className={'intermediateProgressBarWrapper'}
                            style={styles.intermediateProgressBarWrapper}>
                            <div
                              className={'intermediateProgressBarContainer'}
                              style={styles.intermediateProgressBarContainer}>
                              <div
                                style={{
                                  position: 'relative',
                                  flex: 1,
                                  maxWidth: 150,
                                  top: 3,
                                }}>
                                <ProgressBar progress={progress} live={eventStatus === 'live'} />
                              </div>
                              {timeLeftString && (
                                <div
                                  className={'intermediateProgressBarText'}
                                  style={styles.intermediateProgressBarText}>{`${timeLeftString}`}</div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <span className={'intermediateDescription'} style={styles.intermediateDescription}>
                  {item?.description}
                </span>
                <div className={'intermediateMetaContainer'} style={styles.intermediateMetaContainer}>
                  {item?.metadata?.map((meta, index: number) => (
                    <div key={index} className={'intermediateMeta'} style={styles.intermediateMeta}>
                      <span className={'intermediateMetaName'} style={styles.intermediateMetaName}>
                        {meta.name}
                      </span>
                      <span className={'intermediateMetaValue'} style={styles.intermediateMetaValue}>
                        {meta.value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {!item?.wideBackdrop?.src ? (
              <div className={'intermediateRight'} style={styles.intermediateRight}>
                <div className={'intermediateImgContainer'} style={styles.intermediateImgContainer}>
                  <img
                    src={item?.sixteenNineCover?.src}
                    alt={''}
                    className={'intermediateImg'}
                    style={styles.intermediateImg}
                  />
                </div>
              </div>
            ) : //todo: implement wide backdrop
            null}
          </div>
          {row ? (
            <div className={'intermediateMediaRowContainer'} style={styles.intermediateMediaRowContainer}>
              <MediaRow {...row} />
            </div>
          ) : null}
        </div>
      </div>
    </LoadingScreen>
  )
}

const styles = {
  container: {
    width: '100%',
    //overflow: 'hidden',
  } as React.CSSProperties,
  intermediateBackgroundImg: {
    aspectRatio: '16 / 9',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  } as React.CSSProperties,
  intermediateBackgroundOverlay: {
    height: '100%',
    width: '100%',
    opacity: 1,
    position: 'relative',
    transition: 'all .3s ease-in-out',
  } as React.CSSProperties,
  intermediateBackgroundOverlayCover: {} as React.CSSProperties,
  intermediateBackgroundOverlayTop: {} as React.CSSProperties,
  intermediateBackgroundOverlayBottom: {} as React.CSSProperties,
  intermediateContainer: {
    display: 'flex',
    padding: '200px 90px 0px 90px',
    alignItems: 'center',
    justifyContent: 'space-between',
  } as React.CSSProperties,
  intermediateLeft: {
    // flex: 1,
    minWidth: 706,
    maxWidth: 706,
  } as React.CSSProperties,
  intermediateContent: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  } as React.CSSProperties,
  intermediateHeaderContainer: {
    display: 'flex',
  } as React.CSSProperties,
  intermediateHeader: {
    color: '#FFFFFF',
    fontSize: 13,
    fontWeight: 500,
    opacity: 1,
    display: 'flex',
    textAlign: 'left',
  } as React.CSSProperties,
  intermediateHeaderInnerContainer: {
    paddingTop: 18,
    paddingBottom: 5,
  } as React.CSSProperties,
  intermediateHeaderRight: {
    paddingLeft: 22,
  } as React.CSSProperties,
  purchasedIcon: {
    marginRight: 6,
    width: 12,
  } as React.CSSProperties,
  intermediateHeaderDivider: {
    backgroundColor: '#404040',
    width: '100%',
    height: 1,
    marginTop: 25,
  } as React.CSSProperties,
  intermediateTitle: {
    textAlign: 'left',
    fontSize: 38,
    letterSpacing: 0,
    fontWeight: 700,
    color: '#FFFFFF',
    opacity: 1,
  } as React.CSSProperties,
  intermediateSubtitle: {
    textAlign: 'left',
    fontSize: 20,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    fontWeight: 700,
    marginTop: 20,
  } as React.CSSProperties,
  intermediateLogoContainer: {
    marginBottom: 20,
    opacity: 1,
  } as React.CSSProperties,
  intermediateLogo: {
    height: 'auto',
    width: 250,
  } as React.CSSProperties,
  intermediateDescription: {
    textAlign: 'left',
    fontSize: 17,
    fontWeight: 400,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    lineHeight: 1.45,
    whiteSpace: 'pre-wrap',
  } as React.CSSProperties,
  intermediateBtnContainer: {
    display: 'flex',
    marginTop: 23,
    marginBottom: 30,
    width: '100%',
    position: 'relative',
    zIndex: 1,
    flexWrap: 'wrap',
  } as React.CSSProperties,
  intermediateBtnWrapper: {
    display: 'flex',
  } as React.CSSProperties,
  paywallInfoWrapper: {
    flex: 1,
    display: 'flex',
  } as React.CSSProperties,
  paywallInfoSingleWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 500,
    fontSize: 15,
    font: 'SF Pro Text',
  } as React.CSSProperties,
  paywallInfoIcon: {
    color: 'white',
    height: 22,
    width: 22,
    marginRight: 6,
  } as React.CSSProperties,
  paywallInfoText: {
    fontSize: 13,
    whiteSpace: 'nowrap',
  } as React.CSSProperties,
  paywallInfoDivider: {
    height: '60%',
    backgroundColor: 'rgb(64, 64, 64)',
    width: 1,
    marginLeft: 12,
    marginRight: 12,
  } as React.CSSProperties,
  intermediateInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  } as React.CSSProperties,
  intermediateInfoContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties,
  intermediateUpcomingText: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 13,
  } as React.CSSProperties,
  paywallTextOverflowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  } as React.CSSProperties,
  paywallTextOverflow: {
    marginBottom: 5,
  } as React.CSSProperties,
  intermediateProgressBarWrapper: {
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  } as React.CSSProperties,
  intermediateProgressBarTopText: {
    color: '#FFFFFF',
    fontSize: 13,
    fontWeight: 500,
    width: '100%',
    textAlign: 'left',
    marginBottom: 12,
  } as React.CSSProperties,
  intermediateProgressBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 'auto',
    width: '100%',
  } as React.CSSProperties,
  intermediateProgressBarText: {
    color: '#BFBFBF',
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    marginLeft: 16,
  } as React.CSSProperties,
  intermediateDurationText: {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: 13,
  } as React.CSSProperties,
  intermediateMetaContainer: {
    marginTop: 35,
  } as React.CSSProperties,
  intermediateMeta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 14,
  } as React.CSSProperties,
  intermediateMetaName: {
    textAlign: 'left',
    fontSize: 13,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    minWidth: 80,
    maxWidth: 80,
    fontWeight: 700,
  } as React.CSSProperties,
  intermediateMetaValue: {
    textAlign: 'left',
    fontSize: 13,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
  } as React.CSSProperties,
  intermediateRight: {
    boxSizing: 'border-box',
    aspectRatio: '16 / 9',
    width: '50%',
    padding: '0 50px',
  } as React.CSSProperties,
  intermediateImgContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  } as React.CSSProperties,
  intermediateImg: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    filter: 'drop-shadow(7px 7px 5px #00000058)',
  } as React.CSSProperties,
  intermediateMediaRowContainer: {
    marginTop: 75,
  } as React.CSSProperties,
  break: {
    flexBasis: '100%',
    height: 0,
  } as React.CSSProperties,
}

export default Intermediate
