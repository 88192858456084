import React from 'react'
import { WindowSize } from '../../../constants/enums'
import useLiveNow from '../../../utils/hooks/useLiveNow'
import LiveNowIconMobile from '../../../assets/LiveNowIndicatorMobile.svg'
import LiveNowButton from './LiveNowButton'
import LiveNowMediaItem from './LiveNowMediaItem'
import Dropdown from '../../../common/Dropdown'
import { useSelector } from 'react-redux'
import { selectWindowSize } from '../../../store/base/commonSlice'

const LiveNowDropdown = () => {
  const liveNow = useLiveNow()
  const windowSize = useSelector(selectWindowSize)

  return liveNow.length ? (
    <Dropdown>
      {windowSize === WindowSize.Mobile || windowSize === WindowSize.DesktopMedium ? (
        <div style={styles.buttonMobile}>
          <img src={LiveNowIconMobile} alt={'icon'} />
        </div>
      ) : (
        <div style={styles.buttonDesktop}>
          <LiveNowButton />
        </div>
      )}
      <Dropdown.Menu
        heading={'live now'}
        containerStyle={styles.menuContainer}
        innerContainerStyle={styles.menuInnerContainer}
        items={liveNow}
        components={{
          Item: LiveNowMediaItem,
        }}
      />
    </Dropdown>
  ) : null
}

const styles: CSSObject = {
  buttonDesktop: {
    marginRight: 0,
  } as React.CSSProperties,
  buttonMobile: {
    padding: 10,
    height: 17,
    cursor: 'pointer',
    display: 'flex',
  } as React.CSSProperties,
  menuContainer: {
    width: 448,
    alignSelf: 'end',
    maxHeight: 'calc(100vh - 163px - 15px)',
  },
  menuInnerContainer: {
    gap: 38,
    mobile: {
      gap: 20,
    },
  } as React.CSSProperties,
}

export default LiveNowDropdown
